import {useEffect, useState} from "react";
import ApiDatabase from 'server'
import {ChevronDown} from "../../../assets/icons";
import {toast} from "react-toastify";

const PreferenceQualification = ({userData, setUserData, setValidFormPreferenceQualification}) => {
    const [searchPreferenceQualification, setSearchPreferenceQualification] = useState('');
    const [preferenceQualificationList, setPreferenceQualificationList] = useState([]);
    const [preferenceQualificationListGrouped, setPreferenceQualificationListGrouped] = useState([]);
    const qualificationsTypes = {
        'sale': {
            name: 'Vente',
            icon: '🛍',
            isOpen: false,
        },
        'food': {
            name: 'Restauration',
            icon: '🍳',
            isOpen: false,
        },
        'logistic': {
            name: 'Logistique',
            icon: '📦',
            isOpen: false,
        },
        'administration': {
            name: 'Administration',
            icon: '🧾',
            isOpen: false,
        },
        'largeDistribution': {
            name: 'Grande distribution',
            icon: '🛒',
            isOpen: false,
        },
        'industry': {
            name: 'Industrie',
            icon: '🏭',
            isSelected: false,
        },
        'other': {
            name: 'Autre',
            icon: '👷',
            isOpen: false,
        },
    };
    const [dropdownOpen, setDropdownOpen] = useState(qualificationsTypes);
    const [selectedQualifications, setSelectedQualifications] = useState([]);
    const [selectedQualificationsList, setSelectedQualificationsList] = useState([]);
    const [preferenceQualificationChoice, setPreferenceQualificationChoice] = useState([]);
    const [isWaiting, setIsWaiting] = useState(false);

    useEffect(() => {
        setIsWaiting(true)
        ApiDatabase.getAllQualificationsPreferences({}, (data) => {
            setPreferenceQualificationList(data)
            setIsWaiting(false)
        }, (err) => {
            console.log(err)
        })
    }, []);

    useEffect(() => {
        const groupedPreferenceQualification = preferenceQualificationList.reduce((result, item) => {
            const type = item.type;
            if (!result[type]) {
                result[type] = [];
            }
            result[type].push(item);
            return result;
        }, {});

        setPreferenceQualificationListGrouped(groupedPreferenceQualification);
    }, [preferenceQualificationList]);

    useEffect(() => {
        if (selectedQualifications.length > 5) {
            toast.error('Vous ne pouvez pas sélectionner plus de 5 métiers')
            setSelectedQualifications(selectedQualifications.slice(0, 5))
        } else {
            setSelectedQualificationsList(selectedQualifications)
            setUserData({...userData, preferenceQualifications: selectedQualifications})
        }
    }, [selectedQualifications]);

    useEffect(() => {
        if (searchPreferenceQualification) {
            const preferenceQualificationChoices = []
            for (let i = 0; i < preferenceQualificationList.length; i++) {
                //compare searchPreferenceQualification with preferenceQualificationList[i].name, escape special characters and accents and case insensitive and spaces and uppercase
                //replace œ by oe, replace éèêë by e, replace àâä by a, replace ùûü by u, replace îï by i, replace ôö by o, replace ç by c, replace characters other than letters by nothing
                const regex = new RegExp(searchPreferenceQualification.replace(/œ/g, 'oe').replace(/[éèêë]/g, 'e').replace(/[àâä]/g, 'a').replace(/[ùûü]/g, 'u').replace(/[îï]/g, 'i').replace(/[ôö]/g, 'o').replace(/[ç]/g, 'c').replace(/[^a-zA-Z]/g, ''), 'i')
                if (regex.test(preferenceQualificationList[i].name.replace(/œ/g, 'oe').replace(/[éèêë]/g, 'e').replace(/[àâä]/g, 'a').replace(/[ùûü]/g, 'u').replace(/[îï]/g, 'i').replace(/[ôö]/g, 'o').replace(/[ç]/g, 'c').replace(/[^a-zA-Z]/g, ''))) {
                    preferenceQualificationChoices.push(preferenceQualificationList[i])
                }
            }
            setPreferenceQualificationChoice(preferenceQualificationChoices)
        } else {
            setPreferenceQualificationChoice([])
        }
    }, [searchPreferenceQualification]);

    useEffect(() => {
        if (selectedQualifications.length > 0) {
            setValidFormPreferenceQualification(true)
        } else {
            setValidFormPreferenceQualification(false)
        }
    }, [selectedQualifications]);

    useEffect(() => {
        if(userData.preferenceQualifications.length > 0) {
            setSelectedQualifications(userData.preferenceQualifications)
        }
    }, [userData.preferenceQualifications])

    return (
        <div className="rounded-md -space-y-px">
            <div className="flex flex-col gap-3">
                <div className="relative flex flex-col gap-2">
                    <input
                        name="preferenceQualification"
                        value={searchPreferenceQualification}
                        onChange={(e) => setSearchPreferenceQualification(e.target.value)}
                        className={`w-full h-10 pl-3 pr-8 ft-b border rounded-lg`}
                        type={'text'}
                        placeholder={'Rechercher un métier ...'}
                        required
                    />
                    <div className={'flex flex-wrap gap-2 text-sm'}>
                        {selectedQualificationsList.map((qualification, index) => (
                            <div key={index} className={'flex flex-row gap-2 rounded-full bg-gray-100 py-0.5 px-2'}>
                                <div className={'my-auto'}>{preferenceQualificationList.find((item) => item._id === qualification)?.name}</div>
                                <div className={'my-auto cursor-pointer text-gray-500'} onClick={() => setSelectedQualifications(selectedQualifications.filter((item) => item !== qualification))}>x</div>
                            </div>
                        ))}
                    </div>
                </div>
                {isWaiting ? (
                    <div className={'flex flex-col gap-2'}>
                        {[1, 2, 3, 4, 5].map((i, index) => (
                            <div key={index} className={'flex flex-col border rounded'}>
                                <div className={'flex justify-between px-2 py-3'}>
                                    <div className={'flex gap-2 my-auto'}>
                                        <div className={'my-auto text-2xl'}>
                                            <div className={'h-8 bg-gray-200 rounded w-8'}></div>
                                        </div>
                                        <div className={'my-auto text-lg'}>
                                            <div className={'h-4 bg-gray-200 rounded w-40'}></div>
                                        </div>
                                    </div>
                                    <div className={'flex rounded w-9 h-9 my-auto justify-center align-middle items-center'}>
                                        <div className={'w-9 h-9 bg-gray-200 rounded'}></div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : (
                    <>
                        {Object.keys(preferenceQualificationListGrouped).length > 0 && (
                            <div className={'flex flex-col gap-2'}>
                        {Object.keys(preferenceQualificationListGrouped).map((qualificationType, index) => {
                            if (preferenceQualificationChoice.length > 0 && !preferenceQualificationChoice.find((item) => item.type === qualificationType)) return null

                            return (
                                <div key={index} className={'flex flex-col border rounded'}>
                                    <div className={'flex justify-between px-2 py-3 cursor-pointer'}
                                         onClick={() => setDropdownOpen({
                                                     ...dropdownOpen,
                                                     [qualificationType]: {
                                                         ...dropdownOpen[qualificationType],
                                                         isOpen: !dropdownOpen[qualificationType].isOpen
                                                     }

                                                 })}>
                                                <div className={'flex gap-2 my-auto'}>
                                                    <div
                                                        className={'my-auto text-2xl'}>{qualificationsTypes[qualificationType]?.icon}</div>
                                                    <div
                                                        className={'my-auto text-lg'}>{qualificationsTypes[qualificationType]?.name}</div>
                                                </div>
                                                <div
                                                    className={'flex border rounded w-9 h-9 my-auto justify-center align-middle items-center hover:bg-gray-100'}>
                                                    <ChevronDown w={20} h={9} color={'#374151'}/>
                                                </div>
                                            </div>
                                            {dropdownOpen[qualificationType].isOpen && (
                                                <div className={'border-t'}>
                                                    <div className={'flex flex-col gap-4 px-3 py-4 text-sm'}>
                                                        {preferenceQualificationListGrouped[qualificationType].map((qualification, index) => {
                                                            if(preferenceQualificationChoice.length > 0 && !preferenceQualificationChoice.find((item) => item._id === qualification._id)) return null

                                                            return (
                                                                <label key={index} className={'flex flex-row gap-2 cursor-pointer'}>
                                                                    <div className={'my-auto flex'}>
                                                                        <input type="checkbox"
                                                                               className={'w-4 h-4 accent-yellow-400'}
                                                                               onChange={() => {
                                                                                   if (selectedQualifications.includes(qualification._id)) {
                                                                                       setSelectedQualifications(selectedQualifications.filter((item) => item !== qualification._id))
                                                                                   } else {
                                                                                       setSelectedQualifications([...selectedQualifications, qualification._id])
                                                                                   }

                                                                               }}
                                                                               checked={selectedQualifications.includes(qualification._id)}

                                                                        />
                                                                    </div>
                                                                    <div className={'my-auto'}>{qualification.name}</div>
                                                                </label>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default PreferenceQualification;